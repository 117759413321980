import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { userAtom } from '../recoil/userAtoms';
import cardViewIcon from '../icons/cardViewIcon.png';
import listViewIcon from '../icons/listViewIcon.png';
import Table from '../components/Table/Table';
import WorkspaceCard from '../components/Cards/WorkspaceCard';
import AddWorkspaceCard from '../components/Cards/AddWorkspaceCard';
import PageWrapper from '../components/PageWrapper';
import { Logger } from '../utils/logger';
import CreateWorkspaceModal from '../components/Modal/CreateWorkspaceModal';
import {
  activeWorkspaceAtom,
  showCardAtom,
  workspacesAtom,
} from '../recoil/workspaceAtom';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../components/Modal/DeleteModal';

const Workspaces = () => {
  const [user] = useRecoilState(userAtom);
  const [workspaces, setWorkspaces] = useRecoilState(workspacesAtom);
  const [, setActiveWorkspace] = useRecoilState(activeWorkspaceAtom);
  const [showCard, setShowCard] = useRecoilState(showCardAtom);
  const navigate = useNavigate();
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isViewMembers, setIsViewMembers] = useState(false)

  const fetchWorkspaces = async () => {
    try {
      const res = await axios.get(
        `/api/workspace/getWorkspaces?firebaseAuthUUID=${user?.firebaseAuthUUID}`
      ); // Replace with your actual API endpoint
      setWorkspaces(res.data);
      
      Logger.log(res.data);
    } catch (error) {
      Logger.error('Failed to fetch workspaces:', error);
    }
  };

  useEffect(() => {
    fetchWorkspaces();
  }, []);

  const handleDelete = async () => {
    try {
      await axios.put('/api/workspace/deleteWorkspaces', {
        workspaceIds: selectedWorkspaces,
      });

      fetchWorkspaces();

      setSelectedWorkspaces([]);
      setOpenDeleteModal(false);
    } catch (error) {
      Logger.error('Failed to delete workspaces:', error);
      // Handle the error properly, e.g., by showing a notification to the user
    }
  };

  return (
    <PageWrapper>
      <div className="mt-6 flex justify-between h-9">
        <div className="text-3xl font-semibold">Workspaces</div>
        <div className="flex w-fit h-fit rounded-lg border border-gray-300 overflow-hidden">
          <div
            className={`w-9 h-9 p-2 ${showCard ? 'bg-gray-200' : ''}`}
            onClick={() => {
              setShowCard(true);
            }}
          >
            <img src={cardViewIcon} />
          </div>
          <div
            className={`w-9 h-9 p-2 ${!showCard ? 'bg-gray-200' : ''}`}
            onClick={() => {
              setShowCard(false);
            }}
          >
            <img src={listViewIcon} />
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-6 grow overflow-hidden">
        {showCard ? (
          <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 items-stretch flex-wrap justify-start gap-3 overflow-y-scroll no-scrollbar">
            <AddWorkspaceCard onClick={() => setOpenCreateModal(true)} />
            {workspaces.map((workspace) => (
              <WorkspaceCard
                key={workspace._id}
                data={workspace}
                handleDelete={() => {
                  setSelectedWorkspaces([workspace._id]);
                  setOpenDeleteModal(true);
                }}
                handleEdit={() => {
                  setSelectedWorkspace(workspace);
                  setOpenCreateModal(true);
                }}
                handleViewMembers={() => {
                  setIsViewMembers(true);
                  setSelectedWorkspace(workspace);
                  setOpenCreateModal(true);
                }}
              />
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-3 w-full grow overflow-hidden">
            <div className="flex justify-end">
              <button
                className="p-2 border rounded-lg bg-primary text-white"
                onClick={() => setOpenCreateModal(true)}
              >
                Add new workspace
              </button>
            </div>
            <Table
              cols={5}
              fields={[
                { key: 'name', value: 'Name' },
                { key: 'members', value: 'Members' },
                { key: 'pendingApprovers', value: 'Pending Approvals' },
                { key: 'updated', value: 'Updated' },
              ]}
              data={workspaces.map((workspace) => {
                return {
                  ...workspace,
                  name: workspace.name,
                  members: workspace.workspaceMembers?.length,
                  pendingApprovers: workspace?.pendingApprovers || 0,
                  updated: workspace.lastUpdated
                    ? new Date(workspace.lastUpdated).toLocaleDateString()
                    : 'N/A',
                  id: workspace._id,
                  handleDelete: () => {
                    setSelectedWorkspaces([workspace._id]);
                    setOpenDeleteModal(true);
                  },
                  handleEdit: () => {
                    setSelectedWorkspace(workspace);
                    setOpenCreateModal(true);
                  },
                  handleViewMembers: () => {
                    setIsViewMembers(true)
                    setSelectedWorkspace(workspace);
                    setOpenCreateModal(true);
                  },
                  navigate: () => {
                    setActiveWorkspace(workspace.name);
                    navigate(`/workspace/${workspace._id}`);
                  },
                };
              })}
              bulkDeleteAction={(selectedIds) => {
                setSelectedWorkspaces(selectedIds);
                setOpenDeleteModal(true);
              }}
            />
          </div>
        )}
      </div>

      {openCreateModal && <CreateWorkspaceModal
        isOpen={openCreateModal}
        closeModal={() => setOpenCreateModal(false)}
        fetchWorkspaces={fetchWorkspaces}
        data={selectedWorkspace}
        isEdit={!!selectedWorkspace?._id}
        isViewMembers={isViewMembers}
        setIsViewMembers={setIsViewMembers}
      />}
      <DeleteModal
        openModal={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleDelete={handleDelete}
        message={`Are you sure you want to delete the selected workspace(s)?`}
      />
    </PageWrapper>
  );
};

export default Workspaces;
