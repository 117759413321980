import ApprovalRequestCard from '../Cards/ApprovalRequestCard';

const ApprovalRequestBoardView = ({ approvalRequests, handleDelete }) => {
  const boardColumns = ['Approval Sent', 'Feedback Received', 'Approved'];

  const boardColors = {
    'Approval Sent': 'bg-warning-50 text-warning-600',
    'Feedback Received': 'bg-processing-50 text-processing-600',
    Approved: 'bg-success-50 text-success-600',
  };

  const boardData = {
    'Approval Sent': [],
    'Feedback Received': [],
    Approved: [],
  };

  approvalRequests.forEach((approvalRequest) => {
    const status = approvalRequest.status;
    if (status === 'pending') {
      boardData['Approval Sent'].push(approvalRequest);
    } else if (['needsRevison'].includes(status)) {
      boardData['Feedback Received'].push(approvalRequest);
    } else if (status === 'approved') {
      boardData['Approved'].push(approvalRequest);
    }
  });

  return (
    <div className="w-full overflow-x-scroll no-scrollbar">
      <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-x-7 gap-y-3 text-xs h-full min-w-[950px]">
        {boardColumns.map((column) => (
          <div className="rounded-lg flex gap-4 pb-20 cursor-pointer min-w-fit flex-col h-full overflow-y-scroll no-scrollbar ">
            <div className="flex gap-2 items-center font-semibold mb-3 sticky top-0 bg-white w-full z-10 py-2.5">
              {column}{' '}
              <span
                className={`text-xs min-w-4 text-center rounded-full px-1 ${boardColors[column]}`}
              >
                {boardData[column].length}
              </span>
            </div>
            <div className="flex flex-col gap-4">
              {boardData[column].map((approvalRequest) => (
                <ApprovalRequestCard
                  approvalRequest={approvalRequest}
                  key={approvalRequest.timelineId}
                  handleDelete={handleDelete}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApprovalRequestBoardView;
