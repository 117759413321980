import Spinner from "./Spinner/Spinner";

const UploadLoader = ({ loading }) => {
  return (
    loading ? <div className="flex justify-center items-center absolute top-0 left-0 w-full h-full z-10 backdrop-blur-sm bg-white bg-opacity-30">
      <Spinner />
    </div>: null
  );
};

export default UploadLoader;
