import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AWS from 'aws-sdk';
import AddApprovalRequestApprovers from '../components/CreateApprovalRequestSteps/AddApprovalRequestApprovers';
import AddApprovalRequestDetails from '../components/CreateApprovalRequestSteps/AddApprovalRequestDetails';
import PageWrapper from '../components/PageWrapper';
import StepperIndicator from '../components/Stepper/StepperIndicator';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import { Logger } from '../utils/logger';
import Plus from '../components/SVGs/Plus';
import NextArrow from '../components/SVGs/NextArrow';
import { imagesDataAtom } from '../recoil/imageDataAtom';
import CloseButton from '../components/CloseButton';
import ReactPlayer from 'react-player';
import PdfPreview from '../components/DocumentViewers/PdfPreview';
import DocPreview from '../components/DocumentViewers/DocPreview';
import NoContent from '../components/ContentViewer/NoContent';
import UploadContentModal from '../components/Modal/UploadContent';
import {
  removeAnchorTagHref,
  scrubIframeDoc,
} from '../utils/remove-anchor-href';
import { validateRequest } from '../utils/validateUtils';
import { useToast } from '@chakra-ui/react';
import ApprovalCreatedModal from '../components/Modal/ApprovalSentModal';
import uploadToS3 from '../utils/uploadToS3';
import { planValidationNewAppReq } from '../utils/checkNewARAgainstPlan';
import priceToPlan from '../utils/priceIdToPlan';
import VersionSelector from '../components/DropDowns/VersionSelector';
import UploadLoader from '../components/UploadLoader';

const EditApprovalR = () => {
  const [workspaceID, setWorkspaceID] = useState('');
  const [user] = useRecoilState(userAtom);
  const [imageData, setImageData] = useRecoilState(imagesDataAtom);
  const playerWrapperRef = useRef(null);

  // const [currentImage, setCurrentImage] = useState(0);
  const [category, setCategory] = useState('');
  const [isDesktopView, setIsDesktopView] = useState(true);

  const history = useNavigate();
  const toast = useToast();

  const [currentData, setCurrentData] = useState(null);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openSentModal, setOpenSentModal] = useState(false);

  const [numVideosUploaded, setNumVideosUploaded] = useState(0);
  const [numImagesUploaded, setNumImagesUploaded] = useState(0);
  const [numDocsUploaded, setNumDocsUploaded] = useState(0);
  const [imageDeleted, setImageDeleted] = useState(false);

  const [timelineId, setTimelineId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [approvers, setApprovers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [htmlContents, setHtmlContents] = useState([]);
  const [URL, setURL] = useState('');
  const [platform, setPlatform] = useState('');
  const [socialFormat, setSocialFormat] = useState('');
  const [description, setDescription] = useState('');
  const [stepOneData, setStepOneData] = useState({
    title: '',
    dueDate: '',
    description: '',
    caption: '',
    link: '',
    level: '',
    label: '',
    autoApprove: false,
    imageUrl: {},
    selectedApprovers: [],
    workspaceID,
    userID: user?._id,
    workspaceMembers: members,
    platform,
    category,
    socialFormat,
    firebaseAuthUUID: user?.firebaseAuthUUID,
    // totalFileSize: imData[1],
  });

  // const [socialFormat, setSocialFormat] = useState('');
  const [approvalTitle, setApprovalTitle] = useState('');
  const [dueDate, setDueDate] = useState(new Date());
  // const [description, setDescription] = useState('');
  const [caption, setCaption] = useState('');
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [mediaIsChanged, setMediaIsChanged] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [selectedApprovals, setSelectedApprovals] = useState([]);
  const { approvalReqID } = useParams();
  const [contentTypeCategory, setContentTypeCategory] = useState({});
  const [subCat, setSubCat] = useState('');
  const [labelOptions, setLabelOptions] = useState([
    { label: 'Urgent', value: 'Urgent' },
    { label: 'Important', value: 'Important' },
    { label: 'Normal', value: 'Normal' },
  ]);

  const [timelineApprovalRequests, setTimelineApprovalRequests] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(approvalReqID);
  const [versionSelect, setVersionSelect] = useState(false);
  const [newVersion, setNewVersion] = useState(false);
   const [attachments, setAttachments] = useState([]);

   const addAttachments = (newAttachments) => {
     if (attachments.length >= 25) {
       alert("You can only upload up to 25 attachments.");
       return;
     }
     setAttachments([...attachments, ...newAttachments]);
   };

  const handleVersionSelection = (requestId) => {
    setSelectedVersion(requestId);
    // Construct the new URL with the request._id query parameter
    const url = `/edit/${requestId}`;
    history(url); // This changes the URL and navigates to the new route
    setNewVersion(false);
  };

  const handleAddLabel = (newLabelOption) => {
    const findLabel = labelOptions.find(
      (label) => label.value === newLabelOption.value
    );

    if (!findLabel) {
      setLabelOptions([...labelOptions, newLabelOption]);
    }
  };

  const [step, setStep] = useState(1);

  const handleNextAction = () => {
    setStep(step + 1);
  };

  const handleStepChange = (step) => {
    if (isSubmitting) return;

    setStep(step);
  };

  const handleDotClick = (index) => {
    setCurrentPage(index);
  };

  const fetchWorkspaceMembers = async (workSpaceId, workspaceMemberIds) => {
    try {
      // setIsLoading(true); // Assuming you have this function to set loading state

      const res = await axios.get(
        `/api/workspace/getWorkspaceMembers?workspaceID=${workSpaceId}`
      );
      const data = res.data;

      // Assuming each member object contains an 'email' field
      let _members = data.map((member) => ({
        id: member._id,
        name: member.email,
      }));
      setMembers(_members);
      setSelectedMembers(
        _members.filter((member) => {
          return workspaceMemberIds.includes(member.id);
        })
      );
      // setEmails(_members.map(email => ({ email, level: null })));
      // setIsLoading(false);
    } catch (error) {
      Logger.error('Error fetching workspace members:', error);
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    // const updateWidth = () => {
    //   setMaxWidth(`${(window.innerWidth * 5) / 12}px`);
    // };
    // window.addEventListener('resize', updateWidth);
    // updateWidth(); // initialize maxWidth
    const fetchExistingApprovalRequest = async () => {
      try {
        const res = await axios.get(
          `/api/approvalRequests/get/${approvalReqID}?firebaseUID=${user?.firebaseAuthUUID}`
        );
        const data = res.data.approvalRequest;

        setStepOneData({
          title: data.approvalTitle.trim(),
          dueDate: new Date(data.dueDate).toISOString().split('T')[0],
          caption: data.content.caption.trim(),
          link: data.link,
          label: data.label,
          autoApprove: data.autoApprove,
          autoApproveDays: data.autoApproveDays,
        });

        setApprovalTitle(data.approvalTitle.trim());
        setDueDate(new Date(data.dueDate));
        setDescription(data.description);
        setCaption(data.content.caption.trim());
        setLink(data.content.link);
        setImageData(data.content?.media);
        setApprovers(
          data.approvers.map((approver) => ({
            ...approver,
            level: approver.approverLevel,
          }))
        );

        setLink(data.link);
        Logger.log(res.data);
        setContentTypeCategory(data.contentTypeCategory);
        setSocialFormat(data.socialFormat);

        setWorkspaceID(data.workspaceID);
        setTimelineId(res.data.timelineID);
        setTimelineApprovalRequests(res.data.timeline);

        // setSelectedMembers()

        //   setImageData(data.content.media);
        // setHtmlContents(data.content.media);
        // setHtmlContent(data.content.media[0].src);
        setSubCat(data.contentTypeCategory.subCategory);
        setPlatform(data.contentTypeCategory.subCategory);

        await fetchWorkspaceMembers(data.workspaceID, data.workspaceMembers);
      } catch (error) {
        Logger.error('Error fetching existing approval request:', error);
      }
      setIsLoading(false);
    };

    fetchExistingApprovalRequest();

    // const category = new URLSearchParams(window.location.search).get('category');

    return () => {
      // window.removeEventListener('resize', updateWidth);
      // setImageData([]);
    };
  }, [selectedVersion]);

  const handleMemberSelection = (list) => {
    // const selectMember = selectedMembers.find( member => member._id === id);
    // if (selectMember) {
    //   return;
    // }

    // const memberSelected = members.find(member => member._id === id);
    setSelectedMembers(list);
  };

  const handleRemoveWorkspaceMember = (id) => {
    const newMembers = selectedMembers.filter((member) => member._id !== id);
    setSelectedMembers(newMembers);
  };

  const handleApprovalDetails = (name, value) => {
    setStepOneData({
      ...stepOneData,
      [name]: value,
    });
  };

  const handleDescription = (value) => {
    setDescription(value);
  };

  const addImageData = (newData) => {
    if (isUpdate) {
      setImageData((preImageData) => {
        const updatedData = preImageData.map((data, index) => {
          if (index === currentPage) {
            return newData;
          }
          return data;
        });
        return updatedData;
      });
    } else {
      setImageData((preImageData) => [...preImageData, newData]);
    }
    setMediaIsChanged(true);
    setIsUpdate(false);
  };

  const handleOpenUploadModal = () => {
    setOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    setIsUpdate(false);
  };

  const handleCloseSentModal = () => {
    // clear state
    setImageData([]);
    setApprovers([]);
    setStepOneData({
      title: '',
      dueDate: '',
      description: '',
      caption: '',
      link: '',
      label: '',
      autoApprove: false,
      autoApproveDays: 7,
      imageUrl: {},
      selectedApprovers: [],
      workspaceID,
      userID: user?._id,
      workspaceMembers: members,
      platform,
      category,
      socialFormat,
      firebaseAuthUUID: user?.firebaseAuthUUID,
    });

    setOpenSentModal(false);
    history('/approvalRequest/' + timelineId);
  };

  const handleSetHtmlContent = (content) => {
    setHtmlContents([...htmlContents, content]);
  };

  const handleHtmlContentUrl = (url) => {
    setURL(url);
  };

  const handleNextSlide = () => {
    if (currentPage < (htmlContents.length || imageData.length) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevSlide = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePlatformSelection = (platform, socialFormat) => {
    platform && setPlatform(platform);
    socialFormat && setSocialFormat(socialFormat);
  };

  const handleCreateApprovalRequestEmail = async () => {
    // setIsLoading2(true);
    setIsSubmitting(true);
    const missingFields = validateRequest(
      stepOneData.title,
      stepOneData.dueDate,
      description,
      approvers,
      htmlContents
    );

    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description: `Please fill the following required fields: ${missingFields.join(
          ', '
        )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      // setIsLoading2(false);
      setIsSubmitting(false);
      return;
    }

    try {
      const payload = {
        title: stepOneData.title,
        dueDate: stepOneData.dueDate,
        description: description,
        caption: stepOneData.caption,
        link: stepOneData.link,
        autoApprove: stepOneData.autoApprove,
        autoApproveDays: stepOneData.autoApprove
          ? stepOneData.autoApproveDays
          : 0,
        // htmlContent: (platform == 'landingPage' || platform == 'website') ? null : htmlContent,
        htmlContents,
        selectedApprovals: approvers,
        workspaceID: workspaceID,
        userID: user?._id,
        workspaceMembers: members,
        platform,
        category,
        socialFormat,
        _URL: (platform == 'landingPage' || platform == 'website') && URL,
        firebaseAuthUUID: user?.firebaseAuthUUID,
      };

      const response = await axios.post(
        '/api/workspace/createApprovalRequestEmail',
        payload
      );
      if (response.status === 201) {
        // alert("Approval Request is good.");
        setOpenSentModal(true);
        // history('/workspace/' + workspaceID)
      }
    } catch (error) {
      Logger.error('Error creating approval request:', error);
      toast({
        title: 'An error occurred.',
        description: `Error creating approval request: ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    // setIsLoading2(false);
    setIsSubmitting(false);
  };

  const s3 = new AWS.S3();

  const moveVideoToBucket = async (videoUrl, targetBucket, type = 'video') => {
    // Extract the bucket name and key from the URL
    const sourceBucket = videoUrl.split('.s3.')[0].split('://')[1];
    const key = videoUrl.split('.com/')[1];

    const { ContentLength } = await s3
      .headObject({
        Bucket: sourceBucket,
        Key: key,
      })
      .promise();
    const fileSizeMB = ContentLength / (1024 * 1024); // Convert size to MB
    Logger.log('fileSizeMB ', fileSizeMB);

    // Copy the object to the new bucket
    await s3
      .copyObject({
        Bucket: targetBucket,
        CopySource: `${sourceBucket}/${key}`,
        Key: key,
      })
      .promise();

    // Delete the original object
    await s3
      .deleteObject({
        Bucket: sourceBucket,
        Key: key,
      })
      .promise();

    // Return the new URL and size
    return {
      type,
      url: `https://${targetBucket}.s3.${AWS.config.region}.amazonaws.com/${key}`,
      size: fileSizeMB, // Include the size in the return object
    };
  };

  const processFiles = async (type) => {
    const newRecord = [];
    let additionalSizeMB = 0; // Initialize additional size
    const newVideoUrls = [];

    const imagesNew = await uploadToS3(
      imageData?.filter((f) => f.type === type),
      user,
      platform === 'document' ? socialFormat : 'image'
    );
    additionalSizeMB += imagesNew.reduce((acc, curr) => acc + curr.size, 0);

    for (const file of imageData?.filter((f) => f.type === 'video')) {
      const moveResult = await moveVideoToBucket(file.data, 'swiftapprove-1');
      additionalSizeMB += moveResult.size; // Accumulate video file sizes
      newVideoUrls.push(moveResult);
    }

    for (const data of imageData) {
      if (
        ['photo', 'image', 'video', type].includes(data?.media) &&
        (data.type == null || data.type == undefined)
      ) {
        newRecord.push(data);

        continue;
      }

      if (data.type === type) {
        newRecord.push(imagesNew.shift());
      }

      if (data.type === 'video') {
        newRecord.push(newVideoUrls.shift());
      }
    }

    // const newVideoUrls = [];

    // let photos = imageData?.filter((f) => f?.type === 'photo');
    // let oldPhotos = imageData?.filter(
    //   (f) => ['photo', 'image'].includes(f?.media) && (f.type == null || f.type == undefined)
    // );

    // console.log('oldPhotos', oldPhotos, imageData);

    // Process image files
    // const imagesNew = await uploadToS3(
    //   imageData?.filter((f) => f.type === type),
    //   user,
    //   platform === 'document' ? socialFormat : 'image'
    // );
    // additionalSizeMB += imagesNew.reduce((acc, curr) => acc + curr.size, 0);

    // Move video files to a different bucket and accumulate sizes
    // for (const file of imageData?.filter((f) => f.type === 'video')) {
    //   const moveResult = await moveVideoToBucket(file.data, 'swiftapprove-1');
    //   additionalSizeMB += moveResult.size; // Accumulate video file sizes
    //   newVideoUrls.push(moveResult);
    // }

    // Now update the totalFileSize state once with the total additional size
    // Logger.log(additionalSizeMB);
    // setTimeout(() => {
    //   setTotalFileSize(additionalSizeMB);
    // }, 1000); // 1000 milliseconds = 1 second

    // Output the results
    // const combinedUrls = imagesNew.concat(newVideoUrls).concat(oldPhotos);
    return [newRecord, additionalSizeMB];
  };

  const handleUpdateApprovalRequestWeb = async () => {
    setIsSubmitting(true);

    const missingFields = validateRequest(
      approvalTitle,
      dueDate,
      description,
      approvers,
      imageData
    );

    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description: `Please fill the following required fields: ${missingFields.join(
          ', '
        )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    if (priceToPlan[user?.plan]?.title == 'STARTER') {
      toast({
        title: 'Upgrade your plan',
        description: `Users on Starter Plan do not have access to version control. Upgrade to Premium to send multiple Approval Requests in one timeline, with comments and version history.`,
        status: 'info',
        duration: 5000,
        isClosable: false,
      });
      setIsSubmitting(false);
      return;
    }

    try {
      let imageUrl;
      //   if(mediaIsChanged){
      //     imageUrl = await uploadToS3(htmlContent, user);
      //   }

      const payload = {
        approvalReqID, // Include the approval request ID

        title: stepOneData.title,
        dueDate: stepOneData.dueDate,
        description: description,
        caption: stepOneData.caption,
        link: stepOneData.link,
        label: stepOneData.label,
        autoApprove: stepOneData.autoApprove,
        autoApproveDays: stepOneData.autoApprove
          ? stepOneData.autoApproveDays
          : 0,

        htmlContents: imageData,
        // htmlContent: (subCat == 'landingPage' || subCat == 'website') ? null : htmlContent,
        approvers,
        // link: linkRef.current.value,
        workspaceID: workspaceID,
        userID: user?._id,
        workspaceMembers: selectedMembers.map((member) => member.id),
        _URL: (platform == 'landingPage' || platform == 'website') && URL,
        mediaIsChanged,
      };

      const response = await axios.post(
        '/api/approvalRequests/update',
        payload
      ); // Assuming the endpoint is for PUT requests

      if (response.status === 200) {
        // alert("Approval Request updated successfully.");
        setOpenSentModal(true);
        setIsSubmitting(false);
      }
    } catch (error) {
      Logger.error('Error updating approval request:', error);
      toast({
        title: 'An error occurred.',
        description: `Error updating approval request: ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
    }
  };

  const handleUpdateApprovalRequest = async () => {
    if (contentTypeCategory.category === 'web') {
      return handleUpdateApprovalRequestWeb();
    }

    setIsSubmitting(true);

    const missingFields = validateRequest(
      approvalTitle,
      dueDate,
      description,
      approvers,
      imageData
    );

    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description: `Please fill the following required fields: ${missingFields.join(
          ', '
        )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    if (priceToPlan[user?.plan]?.title == 'STARTER') {
      toast({
        title: 'An error occurred.',
        description: `Users on Starter Plan do not have access to version control. Upgrade to Premium to send multiple Approval Requests in one timeline, with comments and version history.`,
        status: 'error',
        duration: 5000,
        isClosable: false,
      });
      setIsSubmitting(false);
      return;
    }

    try {
      let imageUrl;
      let mediaSize;
      if (mediaIsChanged) {
        const imData = await processFiles(
          contentTypeCategory.subCategory === 'document'
            ? socialFormat
            : 'image'
        );

        imageUrl = imData[0];
        mediaSize = imData[1];
      }
      // alert('pause  ')
      Logger.log(imageUrl);
      if (imageUrl) {
        imageUrl = imageUrl.map((obj) => ({
          ...obj, // Spread operator to copy existing fields
          type: obj?.media, // Set 'type' field to the value of 'media' field
        }));
      }

      const payload = {
        approvalReqID, // Include the approval request ID
        // title: titleRef.current.value,
        // dueDate,
        // description: descriptionRef.current.value,
        // caption: caprionRef.current.value,

        title: stepOneData.title,
        dueDate: stepOneData.dueDate,
        description: description,
        caption: stepOneData.caption,
        link: stepOneData.link,
        label: stepOneData.label,
        autoApprove: stepOneData.autoApprove,
        autoApproveDays: stepOneData.autoApprove
          ? stepOneData.autoApproveDays
          : 0,

        imageUrl,
        approvers,
        // link: linkRef.current.value,
        workspaceID: workspaceID,
        userID: user?._id,
        workspaceMembers: selectedMembers.map((member) => member.id),
        mediaIsChanged: newVersion,
        totalFileSize: mediaSize,
      };

      const response = await axios.post(
        '/api/approvalRequests/update',
        payload
      ); // Assuming the endpoint is for PUT requests

      if (response.status === 200) {
        setOpenSentModal(true);
        setIsSubmitting(false);
      }
    } catch (error) {
      Logger.error('Error updating approval request:', error);
      toast({
        title: 'An error occurred.',
        description: `Error updating approval request: ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
    }
  };

  const handleCreateApprovalRequest = async () => {
    if (category === 'web') {
      return handleCreateApprovalRequestEmail();
    }

    setIsSubmitting(true);

    // setIsLoading2(true);
    const missingFields = validateRequest(
      stepOneData.title,
      stepOneData.dueDate,
      description,
      approvers,
      imageData
    );
    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description:
          missingFields[0] == 'Your content might still be uploading'
            ? 'Please wait, your content might still be uploading'
            : `Please fill the following required fields: ${missingFields.join(
                ', '
              )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      // setIsLoading2(false);
      setIsSubmitting(false);
      return;
    }

    try {
      const imData = await processFiles(
        platform === 'document' ? socialFormat : 'image'
      );
      let validation = planValidationNewAppReq(user, imData);
      if (!validation.valid) {
        toast({
          title: 'Upgrade required',
          description: validation.msg,
          status: 'info',
          duration: 5000,
          isClosable: false,
        });
        // setIsLoading2(false);
        setIsSubmitting(false);
        return;
      }

      const payload = {
        title: stepOneData.title,
        dueDate: stepOneData.dueDate,
        description: description,
        caption: stepOneData.caption,
        link: stepOneData.link,
        imageUrl: imData[0],
        selectedApprovals: approvers,
        workspaceID: workspaceID,
        userID: user?._id,
        workspaceMembers: members,
        platform,
        category,
        socialFormat,
        firebaseAuthUUID: user?.firebaseAuthUUID,
        totalFileSize: imData[1],
      };

      const response = await axios.post(
        '/api/workspace/createApprovalRequest',
        payload
      );
      if (response.status === 201) {
        // alert("Approval Request is good.");
        setOpenSentModal(true);
        // history('/workspace/' + workspaceID)
      }
    } catch (error) {
      Logger.error('Error creating approval request:', error);
      toast({
        title: 'An error occurred.',
        description: `Error creating approval request: ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    // setIsLoading2(false);
    setIsSubmitting(false);
  };

  function removeItemAtIndex(index) {
    // First, check if the item to be removed is a video
    if (imageData[index].type === 'video') {
      // setIsPlaying(false); // Ensure the ReactPlayer stops playing the video

      // Allow a brief moment for the video to stop
      setTimeout(() => {
        proceedWithRemoval(index);
      }, 100);
    } else {
      // If it's not a video, proceed with removal immediately
      proceedWithRemoval(index);
    }
  }

  function proceedWithRemoval(index) {
    // Determine the new index based on the current state and the item being removed
    let newIndex;
    if (imageData?.length === 1) {
      newIndex = 0;
    } else if (imageData?.length > 1 && currentPage === 0) {
      newIndex = 0;
    } else {
      newIndex = currentPage - 1;
    }

    // Update the state based on the type of the item being removed
    if (imageData[index].type === 'video') {
      setNumVideosUploaded(numVideosUploaded - 1);
    } else {
      setNumImagesUploaded(numImagesUploaded - 1);
    }

    // Filter out the item being removed from imageData
    let imDat = imageData?.filter((_, idx) => idx !== index);

    // Update the states
    setImageDeleted(true);
    setCurrentPage(newIndex);
    setImageData(imDat);
    setMediaIsChanged(true);
  }

  const handleNewVersion = () => {
    setNewVersion(true);
    setVersionSelect(false);
  };

  return (
    <PageWrapper title="Edit Approval Request">
      <div className="flex flex-col sm:flex-row h-full">
        <div className="flex flex-col bg-primary-20 px-6 pt-6 w-[480px] gap-5 rounded-lg pb-10">
          <StepperIndicator
            steps={['Step one', 'Step two']}
            activeStep={step}
            handleStepSelect={handleStepChange}
          />
          <div>
            <h3 className="text-3xl font-semibold">Edit Approval Request</h3>
          </div>
          <div className="flex flex-col grow gap-5 overflow-scroll no-scrollbar pb-10">
            {step === 1 && (
              <AddApprovalRequestDetails
                nextStep={handleNextAction}
                members={members}
                selectedMembers={selectedMembers}
                handleMembersSelect={handleMemberSelection}
                removeMember={handleRemoveWorkspaceMember}
                handleApprovalDetails={handleApprovalDetails}
                approvalRequestData={stepOneData}
                setDescription={handleDescription}
                description={description}
                isSubmitting={isSubmitting}
                platform={platform}
                handleAddLabel={handleAddLabel}
                labelOptions={labelOptions}
                isEdit={true}
                addAttachments={addAttachments}
                attachments={attachments}
                setAttachments={setAttachments}
              />
            )}
            {step === 2 && (
              <AddApprovalRequestApprovers
                setApprovers={setApprovers}
                approvers={approvers}
                isSubmitting={isSubmitting}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col grow-[4] gap-6 border">
          <div className="flex justify-end gap-2">
            <VersionSelector
              handleNewVersion={handleNewVersion}
              newVersion={newVersion}
              handleVersionSelection={handleVersionSelection}
              timelineApprovalRequests={timelineApprovalRequests}
              selectedVersion={selectedVersion}
              setVersionSelect={setVersionSelect}
            />
            <button
              className={`bg-white border rounded-lg py-2.5 px-5 flex text-base font-semibold`}
              onClick={handleUpdateApprovalRequest}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send Revised Request'}
            </button>
          </div>
          <div className='flex flex-col gap-6 overflow-y-scroll h-fit pb-10 no-scrollbar'>

         
          {!htmlContents.length && !imageData.length && (
            <NoContent handleContentUpload={handleOpenUploadModal} />
          )}

          {(!!htmlContents.length || !!imageData.length) && (
            <div
              className={`flex flex-col gap-3 px-12 ${
                isDesktopView ? '' : 'items-center'
              }`}
            >
              <div
                className={`flex px-5 pt-5 border rounded-lg min-w-[582px] max-w-[900px] h-[535px] relative`}
              >
                <UploadLoader loading={isLoading} />
                <div className="border w-full h-full relative group overflow-hidden flex items-center justify-center">
                  <CloseButton
                    onClick={() =>
                      !isSubmitting && removeItemAtIndex(currentPage)
                    }
                    isVisible={
                      (imageData[currentPage]?.src ||
                        imageData[currentPage]?.data) &&
                      !isSubmitting &&
                      true &&
                      newVersion
                    }
                  />
                  {imageData?.length > 0 &&
                  ['image', 'photo'].includes(
                    imageData[currentPage]?.type ??
                      imageData[currentPage]?.media
                  ) ? (
                    <img
                      src={
                        imageData[currentPage]?.data ??
                        imageData[currentPage]?.src
                      }
                      alt={`Post ${currentPage}`}
                      style={{
                        // maxHeight: '50vh',
                        // minHeight: '50vh',
                        objectFit: 'contain',
                        boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                      }}
                      className="h-full w-full"
                    />
                  ) : (imageData[currentPage]?.type ??
                      imageData[currentPage]?.media) === 'video' ? (
                    <div
                      ref={playerWrapperRef}
                      style={{ position: 'relative' }}
                      className="border w-full h-full"
                    >
                      <ReactPlayer
                        url={
                          imageData[currentPage]?.data ??
                          imageData[currentPage]?.src
                        }
                        width="100%"
                        playing={false}
                        height="100%"
                        style={{
                          // maxHeight: '50vh',
                          // minHeight: '50vh',
                          // width: '100%',
                          // height: '100%',
                          objectFit: 'contain',
                          boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                        }}
                        controls={true}
                      />
                    </div>
                  ) : (imageData[currentPage]?.type ??
                      imageData[currentPage]?.media) === 'pdf' ? (
                    <>
                      <PdfPreview
                        data={
                          imageData[currentPage]?.data ??
                          imageData[currentPage]?.src
                        }
                      />
                    </>
                  ) : (imageData[currentPage]?.type ??
                      imageData[currentPage]?.media) === 'html' ? (
                    <div className="w-full h-full">
                      <iframe
                        id="test-iframe"
                        onLoad={scrubIframeDoc}
                        // src='https://swiftapprove.io'
                        loading="eager"
                        srcDoc={removeAnchorTagHref(
                          imageData[currentPage]?.src
                        )}
                        style={{
                          width: isDesktopView ? '200%' : '100%', // Increase width
                          height: isDesktopView ? '200%' : '100%',
                          // width: '200%',
                          // height: "200%",
                          border: 'none',
                          margin: '0 auto',
                          // boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                          transform: isDesktopView
                            ? 'scale(0.5)'
                            : 'scale(1.0)',
                          transformOrigin: '0 0',
                          position: 'relative',
                          // pointerEvents: 'none',
                          overflow: 'scroll',
                        }}
                      ></iframe>
                    </div>
                  ) : (
                    <>
                      <DocPreview
                        data={imageData[currentPage]?.data}
                        url={imageData[currentPage]?.src}
                      />
                    </>
                  )}
                </div>
                {currentPage > 0 && (
                  <div
                    className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full absolute rotate-180 left-0 bottom-2/4 cursor-pointer"
                    onClick={!isSubmitting && handlePrevSlide}
                  >
                    <NextArrow />
                  </div>
                )}
                {currentPage <
                  (htmlContents?.length || imageData?.length) - 1 && (
                  <div
                    className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full absolute right-0 bottom-2/4 cursor-pointer"
                    onClick={!isSubmitting && handleNextSlide}
                  >
                    <NextArrow />
                  </div>
                )}
              </div>
              <div className="flex w-full justify-center gap-1.5 overflow-scroll no-scrollbar">
                {imageData?.map((_, idx) => (
                  <div
                    // className="w-2.5 h-2.5 rounded-full bg-primary"
                    key={idx}
                    onClick={() => !isSubmitting && handleDotClick(idx)}
                    className={`w-2.5 h-2.5 rounded-full bg-primary cursor-pointer ${
                      currentPage === idx ? 'bg-primary' : 'bg-gray-400'
                    }`}
                  ></div>
                ))}
              </div>
            </div>
          )}

          {(!!htmlContents.length || !!imageData.length) && (
            <div className="flex justify-center gap-3">
              {imageData[currentPage] ? (
                <div
                  className="bg-primary text-white hover-shadow px-3 rounded-lg py-2 cursor-pointer flex items-center"
                  onClick={() => {
                    if (isSubmitting) return;
                    setNewVersion(true);
                    handleOpenUploadModal();
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M9.99984 4.16675V15.8334M4.1665 10.0001H15.8332"
                      stroke="white"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Upload new version
                </div>
              ) : null}
              {/* <div
                className="flex justify-center text-white bg-primary px-3 py-2 rounded-lg gap-2 cursor-pointer items-center w-fit"
                onClick={!isSubmitting && handleOpenUploadModal}
              >
                {!imageData[currentPage] ? (
                  <Plus className="stroke-white" />
                ) : null}{' '}
                Add new content
              </div> */}
            </div>
          )}
          </div>
        </div>
      </div>
      <UploadContentModal
        isOpen={openUploadModal}
        closeModal={handleCloseUploadModal}
        category={contentTypeCategory.category}
        setHtmlContent={addImageData}
        setURL={handleHtmlContentUrl}
        setPlatform={handlePlatformSelection}
        setLoading={setIsLoading}
        addImageData={addImageData}
        platform={platform}
        socialFormat={socialFormat}
        loading={isLoading}
      />

      <ApprovalCreatedModal
        isOpen={openSentModal}
        closeModal={handleCloseSentModal}
      />
    </PageWrapper>
  );
};

export default EditApprovalR;
