import { useEffect, useRef, useState } from 'react';
import ChevronDown from '../SVGs/ChevronDown';
import Tick from '../SVGs/Tick';

const VersionSelector = ({
  timelineApprovalRequests,
  newVersion,
  handleNewVersion,
  selectedVersion,
  handleVersionSelection,
}) => {
  const dropdownRef = useRef(null);
  const [versionSelect, setVersionSelect] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setVersionSelect(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="border rounded-lg  flex items-center font-semibold cursor-pointer relative"
      ref={dropdownRef}
    >
      <div
        className="px-3 flex items-center gap-2"
        onClick={() => setVersionSelect(true)}
      >
        {newVersion
          ? 'New Version'
          : `Version 
          ${
            timelineApprovalRequests?.findIndex(
              (version) => version._id === selectedVersion
            ) + 1
          }`}
        <span>
          <ChevronDown />
        </span>
      </div>
      {versionSelect && (
        <div className="absolute w-max bg-white py-2 px-1 flex flex-col rounded-lg border z-20 top-0 right-0 gap-1 max-h-56 overflow-y-scroll no-scrollbar">
          {timelineApprovalRequests.map((request, index) => (
            <div
              className={`flex items-center gap-4 px-2 py-1 rounded-lg cursor-pointer hover:bg-gray-100 ${
                request._id === selectedVersion && !newVersion
                  ? 'bg-gray-200'
                  : ''
              }`}
              key={index}
              onClick={() => {
                handleVersionSelection(request._id);
                setVersionSelect(false);
              }}
            >
              <p>Version {index + 1}</p>
              {request._id === selectedVersion && !newVersion && <Tick />}
            </div>
          ))}
          <div
            className={`flex items-center gap-4 px-2 py-1 rounded-lg cursor-pointer hover:bg-gray-100`}
            onClick={() => {
              handleNewVersion();
              setVersionSelect(false);
            }}

            // onClick={() => handleVersionSelection(request._id)}
          >
            <p>New Version</p>
            {/* {request._id === selectedVersion && <Tick />} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default VersionSelector;
