import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { CloseButton, useToast } from '@chakra-ui/react';
import ApprovalStatus from '../components/ApprovalStatus';
import PageWrapper from '../components/PageWrapper';
import DotIcon from '../components/SVGs/Dot';
import { useNavigate, useParams } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { Logger } from '../utils/logger';
import { getRandomPastelColor } from '../utils/pastels';
import ReactPlayer from 'react-player';
import DocPreview from '../components/DocumentViewers/DocPreview';
import PdfPreview from '../components/DocumentViewers/PdfPreview';
// import { scrubIframeDoc } from '../utils/remove-anchor-href';
import NextArrow from '../components/SVGs/NextArrow';
import SendReminderModal from '../components/Modal/SendReminderModal';
import Iframe from '../components/IFrame';
import useIframe from '../hooks/useIframe';
import formatDate from '../utils/formatDateAbrev';
import ApproverAvatars from '../components/ApproverAvatars';
import Description from '../components/SeeMoreDescription';

const ApprovalRequestTimeL = () => {
  const history = useNavigate();
  const [user] = useRecoilState(userAtom);
  const { timelineID } = useParams();
  const [approvalRequestTimeline, setApprovalRequestTimeline] = useState([]);
  const [timelineName, setTimelineName] = useState('');
  const [latestRequest, setLatestRequest] = useState({});
  const [workspaceIcon, setWorkspaceIcon] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [isSendingReminder, setIsSendingReminder] = useState(false);
  const [approverColors, setApproverColors] = useState({});

  const scrollContainerRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState('95%');
  // const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // const [workspaceIcon, setWorkspaceIcon] = useState(null);
  const toast = useToast();
  const [isDesktopView, setIsDesktopView] = useState(true);
  const playerWrapperRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const { scrubIframeDoc } = useIframe();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(`/api/workspace/getTimeline/${timelineID}`);
        if (res.status === 200) {
          const { approvalRequests, workspaceIcon } = res.data;

          const sortedData = approvalRequests.sort(
            (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
          );

          setApprovalRequestTimeline(sortedData);
          setTimelineName(sortedData[0].approvalTitle.trim());
          setLatestRequest(sortedData[0]);
          setWorkspaceIcon(workspaceIcon); // Assuming you have a state variable for workspace icon

          const approverCols = {};

          const storedData =
            JSON.parse(localStorage.getItem("recoil-persist")) || {};
            const approvals = storedData.approvalRequests || {};

            Object.entries(approvals).forEach(([key, requests]) => {

              // Check if requests is an array and is not empty
              if (Array.isArray(requests) && requests.length !== 0) {
                requests.forEach((request) => {

                  const matchingRequest = sortedData?.find(
                    (dataRequest) =>
                      dataRequest?.approvers?.some((approver) =>
                        request?.approvers?.some(
                          (reqApprover) => approver?._id === reqApprover?._id
                        )
                      )
                  );
             
                    if (matchingRequest?.approvers) {
                      // Loop through approvers and assign bg from request
                      matchingRequest.approvers.forEach((approver) => {
                        const approverEmail = approver.email;
                       request.approvers.forEach((approval) => {
                         if (approval.email === approverEmail && approval.bg) {
                           approver.bg = approval.bg;
                         }
                       });
                      });
                    }
                  
                });
              }
            });
  
         setApprovalRequestTimeline([...sortedData]);

        } else {
          Logger.error('Failed to fetch data');
        }
      } catch (error) {
        Logger.error('An error occurred while fetching data:', error);
      }
      setIsLoading(false);
    };

    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `/api/notifications/notificationsByTimeline/${timelineID}`
        ); // replace '/api/notifications/' with your actual route for fetching notifications
        if (response.status === 200) {
          setNotifications(response.data);
        } else {
          Logger.error('Failed to fetch notifications');
        }
      } catch (error) {
        Logger.error('An error occurred while fetching notifications:', error);
      }
    };

    fetchData();
    fetchNotifications();
  }, []);

  const handleNextSlide = () => {
    if (currentImage < latestRequest?.content?.media?.length - 1) {
      setCurrentImage(currentImage + 1);
    }
  };

  const handlePrevSlide = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    }
  };

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  const handleFullScreen = () => {
    setIsDesktopView(!isDesktopView);
  };

  const handleSendReminder = async () => {
    try {
      const res = await axios.post('/api/approvalRequests/sendReminder', {
        aprReqID: latestRequest?._id,
      });
      // toast
      setShowReminderModal(false);
      toast({
        title: 'Reminders Sent.',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setShowReminderModal(false);
      toast({
        title: 'An error occurred.',
        description: 'Unable to send reminders.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      // Logger.error('An error occurred while sending reminder:', error);
    }
  };

  const approvalDate =
    new Date(latestRequest?.dateCreated).getTime() +
    latestRequest?.autoApproveDays * 24 * 60 * 60 * 1000;

  return (
    <PageWrapper title="Approval request timeline">
      <div className="h-full flex flex-col gap-8">
        <div className="text-3xl font-semibold">{timelineName}</div>
        <div className="flex overflow-x-scroll gap-16 overflow-y-hidden no-scrollbar">
          <div className="max-h-[690px] min-w-[684px] h-full grow max-w-[900px] w-full flex flex-col gap-6">
            <div
              className={`flex items-baseline w-full ${
                latestRequest?.content?.media[currentImage]?.media === 'html'
                  ? 'justify-between'
                  : 'justify-end'
              }`}
            >
              {latestRequest?.content?.media[currentImage]?.media === 'html' ? (
                <div className="flex h-10 bg-gray-200 rounded p-1 cursor-pointer">
                  <div
                    className={`px-2 flex items-center ${
                      isDesktopView ? 'bg-white' : ''
                    }`}
                    onClick={!isLoading && handleFullScreen}
                  >
                    Desktop View
                  </div>
                  <div
                    className={`px-2 flex items-center ${
                      isDesktopView ? '' : 'bg-white'
                    } rounded`}
                    onClick={!isLoading && handleFullScreen}
                  >
                    Mobile View
                  </div>
                </div>
              ) : null}
              <div
                className="border rounded-lg px-3 h-10 flex items-center font-semibold cursor-pointer"
                onClick={() =>
                  !isLoading &&
                  history(`/review?aprReqID=${latestRequest?._id}`)
                }
              >
                View content & Comments
              </div>
            </div>

            <div className={`${isDesktopView ? '' : 'flex justify-center'}`}>
              <div
                className={`flex px-5 pt-5 border rounded-lg min-w-[582px] max-w-[900px] h-[535px] relative ${
                  isDesktopView ? '' : 'w-[250px] '
                }`}
              >
                <div className=" w-full h-full relative group border overflow-hidden flex items-center justify-center">
                  {/* <CloseButton
                            onClick={() => removeItemAtIndex(currentImage)}
                            isVisible={!isSubmitting && true}
                          /> */}
                  {latestRequest?.content?.media?.length > 0 &&
                  ['image', 'photo'].includes(
                    latestRequest?.content?.media[currentImage]?.media
                  ) ? (
                    <img
                      src={latestRequest?.content?.media[currentImage]?.src}
                      alt={`Post ${currentImage}`}
                      style={{
                        // maxHeight: '50vh',

                        // minHeight: '50vh',
                        objectFit: 'contain',
                        boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                      }}
                      className="h-full w-full"
                    />
                  ) : latestRequest?.content?.media[currentImage]?.media ===
                    'video' ? (
                    <div
                      // ref={playerWrapperRef}
                      // style={{ position: 'relative', width: 'fit-content' }}
                      className=" w-full h-full"
                    >
                      <ReactPlayer
                        url={latestRequest?.content?.media[currentImage]?.src}
                        width="100%"
                        playing={false}
                        height="100%"
                        style={{
                          // maxHeight: '50vh',
                          // minHeight: '50vh',
                          // width: '100%',
                          // height: '100%',
                          objectFit: 'contain',
                          boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                        }}
                        controls={true}
                      />
                    </div>
                  ) : latestRequest?.content?.media[currentImage]?.media ===
                    'pdf' ? (
                    <>
                      <PdfPreview
                        data={latestRequest?.content?.media[currentImage]?.src}
                      />
                    </>
                  ) : latestRequest?.content?.media[currentImage]?.media ===
                    'html' ? (
                    <div className="w-full h-full">
                      <Iframe
                        onLoad={scrubIframeDoc}
                        scrubIframeDoc={scrubIframeDoc}
                        srcDoc={latestRequest.content?.media[currentImage]?.src}
                        style={{
                          width: isDesktopView ? '200%' : '100%', // Increase width
                          height: isDesktopView ? '200%' : '100%',
                          border: 'none',
                          margin: '0 auto',
                          transform: isDesktopView
                            ? 'scale(0.5)'
                            : 'scale(1.0)',
                          transformOrigin: '0 0',
                          position: 'relative',
                          pointerEvents: 'none',
                        }}
                        comments={[]}
                        onInferredClick={(e) => {
                          // can add click logic here. not sure if I still need it but will keep for now.
                          scrubIframeDoc(e);
                        }}
                        currentImage={currentImage}
                      />
                    </div>
                  ) : (
                    <>
                      <DocPreview
                        url={latestRequest?.content?.media[currentImage]?.src}
                      />
                    </>
                  )}
                </div>

                {currentImage > 0 && (
                  <div
                    className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full absolute rotate-180 left-0 bottom-2/4 cursor-pointer"
                    onClick={!isLoading && handlePrevSlide}
                  >
                    <NextArrow />
                  </div>
                )}
                {currentImage < latestRequest?.content?.media?.length - 1 && (
                  <div
                    className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full absolute right-0 bottom-2/4 cursor-pointer"
                    onClick={!isLoading && handleNextSlide}
                  >
                    <NextArrow />
                  </div>
                )}
              </div>
            </div>
            <div className="flex w-full justify-center gap-1.5 overflow-scroll no-scrollbar">
              {latestRequest?.content?.media?.map((_, idx) => (
                <div
                  // className="w-2.5 h-2.5 rounded-full bg-primary"
                  key={idx}
                  onClick={() => !isLoading && handleDotClick(idx)}
                  className={`w-2.5 h-2.5 rounded-full bg-primary cursor-pointer ${
                    currentImage === idx ? 'bg-primary' : 'bg-gray-400'
                  }`}
                ></div>
              ))}
            </div>
          </div>
          {/* </div> */}

          {/* </div>
            </div> */}

          <div className="w-[403px] flex flex-col">
            <div className="w-[403px] border rounded-lg">
              <div className="px-6 py-2 flex flex-col border-b text-xs">
                <div className="py-2 flex flex-col gap-3">
                  <ApprovalStatus
                    status={latestRequest?.status}
                    withBorder={true}
                  />
                  {latestRequest?.autoApprove ? (
                    <p className="text-gray-600">
                      {Date.now() < approvalDate
                        ? 'Auto approved set for ' + formatDate(approvalDate)
                        : 'Auto approved on ' + formatDate(approvalDate)}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="px-6 flex flex-col gap-0.5 max-h-40 overflow-x-scroll no-scrollbar relative text-sm">
                <p className="font-semibold sticky top-0 bg-white pt-3 pb-0.5">
                  Assigned to
                </p>
                {approvalRequestTimeline[0]?.approvers &&
                approvalRequestTimeline[0]?.approvers.length > 0 ? (
                  <ApproverAvatars
                    approvers={approvalRequestTimeline[0]?.approvers}
                  />
                ) : (
                  <p>No approvers found.</p>
                )}
              </div>
              <div className="px-6 flex flex-col gap-0.5 max-h-40 overflow-x-scroll no-scrollbar relative text-sm">
                <p className="font-semibold sticky top-0 bg-white pt-3">
                  Created by
                </p>
                <div className="flex gap-3 py-2 text-xs text-gray-700">
                  <div
                    className="w-8 h-8 relative bg-slate-200 rounded-full flex flex-col items-center justify-center cursor-pointer"
                    title={latestRequest?.user?.name}
                  >
                    <img
                      className="w-8 h-8 left-0 top-0 absolute rounded-[200px]"
                      src={latestRequest?.user?.profilePicURL}
                    />
                  </div>
                  <div className="flex flex-col">
                    <p>{latestRequest?.user?.fullName}</p>

                    <p className="text-gray-600">
                      Sent on {formatDate(latestRequest?.dateCreated)}
                    </p>
                  </div>
                </div>
              </div>

              <Description description={latestRequest?.description} />

              <div className="py-4 px-6 flex gap-2">
                <button
                  className="border rounded-lg w-full py-2 font-semibold text-gray-700"
                  onClick={() => !isLoading && setShowReminderModal(true)}
                >
                  Send Reminder
                </button>
                <button
                  className="border rounded-lg w-full py-2 font-semibold text-gray-700"
                  onClick={() => {
                    !isLoading && history('/edit/' + latestRequest?._id);
                  }}
                >
                  Edit Approval
                </button>
              </div>
            </div>
            <div className="w-[403px] grow flex flex-col gap-3 pl-6 pt-4 overflow-hidden">
              <p className="font-semibold">Activity</p>
              <div className="grow overflow-y-scroll flex flex-col no-scrollbar">
                {notifications.map((notification, index) => (
                  <div
                    className="flex gap-3 py-2 text-sm text-gray-700"
                    key={index}
                  >
                    <div
                      className="w-8 h-8 relative bg-slate-200 rounded-full flex flex-col items-center justify-center cursor-pointer"
                      title={
                        notification.initiator?.name ??
                        notification.triggererEmail ??
                        'Status Update'
                      }
                    >
                      {!notification.initiator?.url ? (
                        <div className="text-[#72839a] text-xl font-semibold leading-[30px] text-center uppercase">
                          {notification.initiator?.name?.charAt(0) ??
                            notification.triggererEmail?.charAt(0) ??
                            'U'}
                        </div>
                      ) : (
                        <img
                          className="w-8 h-8 left-0 top-0 absolute rounded-[200px]"
                          src={notification.initiator?.url}
                        />
                      )}
                    </div>
                    <div className="flex flex-col">
                      {notification.triggererEmail === user.email ? (
                        <p>
                          <spam className="font-semibold">You</spam> commented
                          on {timelineName}.
                        </p>
                      ) : (
                        <p>{notification.message}</p>
                      )}
                      <p className="text-gray-600">
                        {new Date(notification.dateCreated).toLocaleString(
                          'en-US',
                          { timeStyle: 'short', dateStyle: 'short' }
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SendReminderModal
        isOpen={showReminderModal}
        closeModal={() => setShowReminderModal(false)}
        handleSendReminder={handleSendReminder}
        isSendingReminder={isSendingReminder}
        pendingApprovers={approvalRequestTimeline[0]?.approvers
          .filter((approver) => approver.status === 'pending')
          .map((approver) => approver.email)}
      />
    </PageWrapper>
  );
};

export default ApprovalRequestTimeL;
