import { useNavigate } from 'react-router-dom';
import Table from '../Table/Table';
import mapStatus from '../../utils/formatApprovalRequestStatus';
import ApprovalStatus from '../ApprovalStatus';
import ApproverAvatars from '../ApproverAvatars';

const ApprovalRequestTableView = ({ approvalRequests, handleDelete }) => {
  const navigate = useNavigate();

  const statusClassNames = {
    'Feedback Received':
      'bg-processing-50 text-processing-700 border-processing-200',
    Approved: 'bg-success-50 text-success-700 border-success-200',
    Rejected: 'bg-error-50 text-error-700 border-error-200',
  };
  return (
    <Table
      fields={[
        { key: 'name', value: 'Name', span: 3 },
        { key: 'approvers', value: 'Approvers' },
        { key: 'dateCreated', value: 'Date Created', span: 2 },
        { key: 'dueDate', value: 'Due Date', span: 2 },
        { key: 'status', value: 'Status', span: 2 },
      ]}
      cols={11}
      data={approvalRequests.map((approvalRequest) => {
        const status = mapStatus(approvalRequest?.status);

        return {
          ...approvalRequest,
          id: approvalRequest.timelineId,
          name: {
            children: (
              <p className="text-primary">{approvalRequest.approvalTitle}</p>
            ),
            span: 3,
          },
          approvers: {
            children: (
              <ApproverAvatars approvers={approvalRequest.approvers} />
            ),
          },
          dateCreated: {
            children: (
              <p className="text-sm">
                {new Date(approvalRequest.dateCreated || null).toLocaleString(
                  undefined,
                  {
                    hour12: true,
                    timeStyle: 'short',
                    dateStyle: 'short',
                  }
                )}
              </p>
            ),
            span: 2,
          },
          dueDate: {
            children: (
              <p className="text-sm">
                {new Date(approvalRequest.dueDate || null).toLocaleString(
                  undefined,
                  {
                    hour12: true,
                    timeStyle: 'short',
                    dateStyle: 'short',
                  }
                )}
              </p>
            ),
            span: 2,
          },
          status: {
            children: (
              <ApprovalStatus status={approvalRequest.status} withBorder={true} />
            ),
            span: 2,
          },
          handleDelete: () => {
            handleDelete([approvalRequest.timelineId]);
          },
          handleEdit: () => {
            navigate(`/edit/${approvalRequest.approvalRequest._id}`);
          },
          navigate: () => {
            navigate(`/approvalRequest/${approvalRequest.timelineId}`);
          },
        };
      })}
      bulkDeleteAction={(selectedIds) => {
        handleDelete(selectedIds);
      }}
    />
  );
};

export default ApprovalRequestTableView;
