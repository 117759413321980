const ApproverAvatars = ({ approvers }) => {
  return (<div className="flex flex-row -space-x-3">
    {approvers.map((approver) => (
      <div
        className={`w-7 h-7 rounded-full flex flex-row text-xs items-center justify-center border-2 border-primary group/item relative`}
        key={approver._id}
        style={{ backgroundColor: approver.bg }}
      >
        {(approver.firstName ?? approver.email)[0].toUpperCase()}{approver.lastName?.[0]?.toUpperCase()}
        <div className={`absolute bottom-full left-full w-max bg-white border p-1 rounded hidden group-hover/item:block z-10`}>
        {approver.firstName ?? approver.email}
        </div>
      </div>
    ))}
  </div>);
}

export default ApproverAvatars;