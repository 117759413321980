import { useState } from 'react';
import TableRow from './TableRow';
import TableHead from './TableHead';

const Table = ({ data, fields, bulkDeleteAction, cols }) => {
  const [selectedIds, setSelectedIds] = useState([]);

  const handleRowSelect = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSelectAll = () => {
    if (data.length === selectedIds.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(data.map((d) => d.id));
    }
  };

  const handleBulkDelete = () => {
    bulkDeleteAction(selectedIds);
  };

  return (
    <div className="overflow-x-scroll w-full grow no-scrollbar h-full overflow-hidden relative">
      <table className="flex flex-col border rounded-lg text-sm h-full overflow-y-scroll no-scrollbar relative">
        <TableHead
          fields={fields}
          showActions={true}
          partial={selectedIds.length && data.length !== selectedIds.length}
          allSelected={data.length && data.length === selectedIds.length}
          deleteAction={handleBulkDelete}
          onSelect={handleSelectAll}
          cols={cols}
        />
        <tbody>
          {data.length ? (
            data.map((d, index) => (
              <TableRow
                key={index}
                fields={fields}
                data={d}
                showActions={true}
                onSelect={() => handleRowSelect(d.id)}
                isSelected={selectedIds.includes(d.id)}
                editAction={d.handleEdit}
                deleteAction={d.handleDelete}
                viewMembersAction={d.handleViewMembers}
                navigate={d.navigate}
                cols={cols}
              />
            ))
          ) : (
            <div className="w-full h-12 flex justify-center items-center">
              <p className="text-center">No data available</p>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
