import { AvatarComponent } from 'avatar-initials';
import { darkerShade } from '../../utils/darkerShade';
import { useEffect, useRef, useState } from 'react';
import { BsFillSendFill } from 'react-icons/bs';
import axios from 'axios';
import { FaTrashAlt } from 'react-icons/fa';
import Reply from './Reply';
import SendMessage from '../SVGs/SendMessage';
import AttachFile from '../SVGs/AttachFile';
import CommentAttachment from '../CommentAttachment/CommentAttachment';
import CommentFileAttachment from '../Comment/CommentFileAttachment';

const extractMentions = (value) => {
  const mentionPattern =
    /(?<=\s|^)@[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z]{2,})(?=\s|$)|(?<=\s|^)@[a-zA-Z0-9_]+(?=\s|$)/g;
  const detectedMentions = value.match(mentionPattern) || [];

  return detectedMentions;
};

const getMentionedUsersEmail = (mentions, users = []) => {
  const mappedUsers = users.reduce((mapper, user) => {
    return { ...mapper, [user.email]: user.email };
  }, {});

  return mentions
    .map((mention) => {
      let nameOrEmail = mention.substring(1);
      return mappedUsers[nameOrEmail];
    })
    .filter((v) => !!v);
};

const RepliesSection = ({
  isSelected,
  email,
  bg,
  comment,
  users,
  currentUser,
  validMentionedUsers,
  validMentions,
  showReplies,
  setReplyCount,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [showReply, setShowReply] = useState(false);
  const [replyList, setReplyList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const cardContainerRef = useRef(null);
  const firstCardRef = useRef(null);
  const textareaRef = useRef(null);
  const uploadRef = useRef(null);

  const [text, setText] = useState('');
  const [showMentionSelect, setShowMentionSelect] = useState(false);
  const [mentions, setMentions] = useState([]);
  const [updateH, setUpdateH] = useState(0);
  const [mentionedText, setMentionedText] = useState('');
  const [attachments, setAttachments] = useState([]);

  const addAttachments = (newAttachments) => {
    setAttachments([...attachments, ...newAttachments]);
  };

  const handleUpdatH = () => {
    setUpdateH((updateH % 2) + 1);
  };

  const handleMentionsCapture = (e) => {
    const cursorPosition = textareaRef.current.selectionStart;
    const textBeforeCursor = e.target.value.slice(0, cursorPosition + 1);
    const atSymbolIndex = textBeforeCursor.lastIndexOf('@');

    if (atSymbolIndex !== -1 || textBeforeCursor === '@') {
      const mentionText = textBeforeCursor.slice(atSymbolIndex + 1);
      setMentionedText(mentionText);
      setShowMentionSelect(true);
    } else {
      setShowMentionSelect(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setText(value);

    // Detect the text being typed after '@'
    handleMentionsCapture(e);

    // const mentionPattern = /(?<=\s|^)@[a-zA-Z0-9_]+(?:\.[a-zA-Z0-9_]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z]{2,})(?=\s|$)|(?<=\s|^)@[a-zA-Z0-9_]+(?=\s|$)/g;
    // const detectedMentions = value.match(mentionPattern) || [];
    const detectedMentions = getMentionedUsersEmail(
      extractMentions(value, users)
    );
    setMentions(detectedMentions);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAddReply();
    }
  };

  const handleShowReply = () => {
    setShowReply(!showReply);
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const getReplies = async () => {
    const response = await axios.get(`/api/comments/${comment._id}/replies`);
    setReplyList(response.data?.records);
    setReplyCount(response.data?.records?.length);
  };

  const handleAddReply = async () => {
    // const mentions = extractMentions(text);
    const mentions = getMentionedUsersEmail(extractMentions(text), users);
    // const reviewerEmail = urlParams.get('reviewer');
    setShowReply(false);
    await axios.post(`/api/comments/${comment._id}/replies`, {
      approvalRequestID: comment.approvalRequestID,
      content: text,
      email: currentUser?.email,
      mentions,
      attachments
    });

    setAttachments([]);

    // setReplyList([response.data, ...replyList]);
    getReplies().then(() => {
      setShowReply(false);
      setText('');
    });
  };

  useEffect(() => {
    const cardContainer = cardContainerRef?.current;

    if (cardContainer?.scrollHeight > cardContainer?.clientHeight) {
      setIsOverflowing(true);
    }
  }, []);

  useEffect(() => {
    comment._id && getReplies();
  }, [comment]);

  useEffect(() => {}, [replyList]);

  const handleSelectMention = (mention) => {
    if (!mention) return;

    const cursorPosition = textareaRef.current.selectionStart;

    const textBeforeCursor = text.slice(0, cursorPosition);
    const textAfterCursor = text.slice(cursorPosition);

    const lastAtSymbolIndex = textBeforeCursor.lastIndexOf('@');

    const replaceFrom = lastAtSymbolIndex === -1 ? 0 : lastAtSymbolIndex + 1;

    const replaceTo = cursorPosition + textAfterCursor.indexOf(' ');

    const newValue =
      text.slice(0, replaceFrom) +
      mention.name +
      ' ' +
      text.slice(replaceTo + 1);

    setText(newValue);
    textareaRef.current.focus();
    // setMentions([]); // Clear mentions list
  };
  return (
    <>
      {replyList.length ? (
        <div
          className={`flex flex-col text-xs gap-2 text-gray-900 ${
            showReplies ? '' : 'hidden'
          }`}
          ref={cardContainerRef}
        >
          {replyList.map((reply, idx) => {
            return (
              <Reply
                bg={bg}
                email={reply.email}
                content={reply.content}
                reply={reply}
                key={reply._id}
                id={reply._id}
                refCard={idx === 0 ? firstCardRef : null}
                users={users}
                attachments={reply.attachments}
                loadReplies={getReplies}
                reviewerEmail={email}
                currentUser={currentUser}
                validMentionedUsers={validMentionedUsers}
                validMentions={validMentions}
              />
            );
          })}
        </div>
      ) : null}

      {(showReply || !replyList.length) && (
        <div className="flex flex-col my-2 relative text-sm z-99 text-gray-900 bg-gra rounded grow">
          <div className="flex items-center my-2 relative text-sm z-99 text-gray-900 bg-gra rounded grow">
            <div
              className="borders mr-2"
              style={{
                borderRadius: '50%',
                height: '25px' /* adjust as needed */,
                width: '25px' /* adjust as needed */,
                minWidth: '25px',
              }}
            >
              {' '}
              {currentUser?.profilePicURL ? (
                <img
                  src={currentUser?.profilePicURL}
                  style={{
                    // position: 'absolute',
                    // top: '-15px' /* adjust as needed */,
                    // left: ' -15px' /* adjust as needed */,
                    borderRadius: '50%',
                    // zIndex: 98,
                    // height: '32px' /* adjust as needed */,
                    // width: '32px' /* adjust as needed */,
                  }}
                />
              ) : (
                <AvatarComponent
                  classes="rounded-full"
                  useGravatar={false}
                  color={darkerShade(bg, 0.55)}
                  background={bg}
                  fontSize={16}
                  fontWeight={400}
                  size={32}
                  initials={currentUser.email.substring(0, 1).toUpperCase()}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              )}
            </div>
            <div className="flex flex-row gap-1 grow text-xs gap-2 rounded-lg bg-gray-100 items-end">
              <textarea
                ref={textareaRef}
                onChange={handleChange}
                className="grow overflow-hidden h-8 p-2 bg-transparent resize-none focus:h-16 focus:border-none text-wrap outline-none"
                rows={1}
                placeholder="Type your text here..."
                value={text}
                onKeyDown={handleKeyDown}
              ></textarea>
              <div className="flex self-stretch items-center justify-center">
                {/* <div
                  className="flex justify-center items-center w-5 h-5 cursor-pointer "
                  onClick={() => {
                    uploadRef.current.click();
                  }}
                >
                  <AttachFile className={'stroke-primary-default'} />
                  <input type='file' hidden ref={uploadRef} />
                  </div> */}
                <CommentAttachment
                  addAttachments={addAttachments}
                  attachments={attachments}
                />
                <div
                  className="flex justify-center items-center rounded-full w-5 h-5 border cursor-pointer "
                  onClick={handleAddReply}
                >
                  <SendMessage className={'stroke-primary-default'} />
                </div>
              </div>
            </div>
            {/* </div> */}

            {showMentionSelect &&
              !!users.filter(
                (user) =>
                  (user.email && user.email.includes(mentionedText)) ||
                  !!(user.name && user.name.includes(mentionedText))
              ).length && (
                <div
                  className="absolute top-full w-60 bg-white p-2 flex flex-col gap-1 rounded border"
                  style={{ zIndex: 999 }}
                >
                  {users
                    .filter(
                      (user) =>
                        ((user.email && user.email.includes(mentionedText)) ||
                          !!(user.name && user.name.includes(mentionedText))) &&
                        user.email !== currentUser.email
                    )
                    .map((user) => {
                      return (
                        <div
                          key={user.id}
                          onClick={() => {
                            handleSelectMention({ name: user.email });
                            setShowMentionSelect(false);
                          }}
                          className="bg-gray-50 hover:bg-gray-100 p-1 rounded"
                          style={{ zIndex: 999 }}
                        >
                          {user.name ?? user.email}
                        </div>
                      );
                    })}
                </div>
              )}
          </div>
          <div className='flex flex-wrap ml-8'>
            {attachments.map((attachment, idx) => {
              return (
                <CommentFileAttachment attachment={attachment} key={attachment._id ?? idx} deleteAction={() => {
                  setAttachments(
                    attachments.filter((_, index) => index !== idx)
                  );
                }}/>
              );
            })}

          </div>
        </div>
      )}
      {replyList.length ? <div
        className={`${
          isSelected ? 'text-white' : 'text-secondary'
        } borders flex justify-end mt-1`}
      >
        <div className="cursor-pointer" onClick={handleShowReply}>
          {showReply ? 'Cancel' : 'Reply'}
        </div>
      </div>: null}
    </>
  );
};

export default RepliesSection;
