import { useState } from "react";
import ApprovalViewedNotification from "./ApprovalViewedNotification";
import CommentNotification from "./CommentNotification";
import StatusChangeNotification from "./StatusChangeNotification";
import axios from "axios";
import { Logger } from "../../utils/logger";
import { useRecoilState, useRecoilValue } from "recoil";
import { userAtom } from "../../recoil/userAtoms";
import {
  unreadNotificationFetcher,
  unreadNotificationsState,
} from "../../recoil/unreadNotificationAtom";

const NotificationItem = ({ notification }) => {
  const { type, seen, _id } = notification;
  const [user] = useRecoilState(userAtom);
  const [isSeen, setIsSeen] = useState(seen)
  const [cleared, setCleared] = useState(false);

  const fetchUnreadNotifications = useRecoilValue(unreadNotificationFetcher);
  const [, setUnreadNotifications] = useRecoilState(unreadNotificationsState);

  const unreadNotification = async () => {
    const hasUnread = await fetchUnreadNotifications(user.firebaseAuthUUID);
    setUnreadNotifications(hasUnread);
  };

  const setNotificationToSeen = async () => {
    try {
      await axios.post('/api/notifications/markAsSeen', { notificationIds: [_id] });
      setIsSeen(true);
      unreadNotification();
    } catch (error) {
      Logger.error(error);
    }
  };


  const clearNotification = async () => {
    try {
      await axios.post('/api/notifications/clearNotification', {
        notificationId: _id,
      });
      setCleared(true);
    } catch (error) {
      Logger.error(error);
    }
  };



  return !cleared ? (
    <>{
      type === 'STATUS_CHANGE' ? (
        <StatusChangeNotification userNotification={notification} isSeen={isSeen}  handleSeen={setNotificationToSeen} clear={clearNotification} />
      ) : type === 'COMMENT_ADDED' ? (
        <CommentNotification userNotification={notification} isSeen={isSeen} handleSeen={setNotificationToSeen} clear={clearNotification} />
      ) : type === 'APPROVAL_VIEWED' ? (
        <ApprovalViewedNotification userNotification={notification} isSeen={isSeen} handleSeen={setNotificationToSeen} clear={clearNotification} />
      ) : (
        <>Coming soon</>
      )
    }</>
  ): null;
}

export default NotificationItem;