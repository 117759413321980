import CheckboxWithTick from '../CheckBox';
import FormDropSelect from '../Form/FormDropSelect';
import FormInput from '../Form/FormInput';
import FormInputSelect from '../Form/FormInputSelect';
import FormTextArea from '../Form/FormTextArea';
import FormTextEditor from '../Form/FormTextEditor';
import CommentFileAttachment from '../Comment/CommentFileAttachment'

const AddApprovalRequestDetails = ({
  nextStep,
  setApprovalRequest,
  members,
  selectedMembers,
  handleMembersSelect,
  removeMember,
  handleApprovalDetails,
  approvalRequestData,
  setDescription,
  description,
  isSubmitting,
  isEdit,
  handleAddLabel,
  labelOptions,
  platform,
  attachments,
  addAttachments,
  setAttachments,
}) => {
  return (
    <>
      <FormInput
        label="Approval title"
        placeholder="Enter title"
        onChange={(e) => handleApprovalDetails('title', e.target.value)}
        value={approvalRequestData.title}
      />
      <FormInput
        label="Due date"
        placeholder="Enter title"
        type="date"
        onChange={(e) => handleApprovalDetails('dueDate', e.target.value)}
        value={approvalRequestData.dueDate}
      />
      <div className="relative w-full">
        <FormTextEditor
          label="Description"
          placeholder="Enter description"
          handleChange={(data) => setDescription(data)}
          name="description"
          value={description}
          addAttachments={addAttachments}
          attachments={attachments}
        />
        <div className="absolute bottom-4 left-0 flex flex-wrap ml-4">
          {attachments.map((attachment, idx) => {
            return (
              <CommentFileAttachment
                attachment={attachment}
                key={attachment._id ?? idx}
                deleteAction={() => {
                  setAttachments(
                    attachments.filter((_, index) => index !== idx)
                  );
                }}
              />
            );
          })}
        </div>
      </div>
      <CheckboxWithTick
        description={'Check to allow system auto approve this request'}
        isSelected={approvalRequestData.autoApprove}
        onCheck={(checked) => handleApprovalDetails('autoApprove', checked)}
      />
      {approvalRequestData.autoApprove && (
        <FormDropSelect
          label="Select Days to Auto Approve"
          placeholder="Select days"
          value={approvalRequestData.autoApproveDays}
          setValue={(value) => handleApprovalDetails('autoApproveDays', value)}
          options={[
            {
              value: 1,
              label: '1 day',
            },
            {
              value: 3,
              label: '3 days',
            },
            {
              value: 7,
              label: '7 days',
            },
            {
              value: 14,
              label: '14 days',
            },
            {
              value: 21,
              label: '21 days',
            },
          ]}
        />
      )}
      {/* {!isEdit && ( */}
        <FormDropSelect
          label="Label"
          placeholder="Select label"
          value={approvalRequestData.label}
          setValue={(value) => handleApprovalDetails('label', value)}
          handleAddLabel={handleAddLabel}
          options={labelOptions}
        />
      {/* )} */}
      {
        ["social"].includes(platform) && <FormTextArea
          label="Caption"
          placeholder="Enter a caption"
          onChange={(e) => handleApprovalDetails('caption', e.target.value)}
          value={approvalRequestData.caption}
        />
      }
      {
        ["social"].includes(platform) && <FormInput
          label="Link to post"
          placeholder="https://www.swiftapprove.io"
          value={approvalRequestData.link}
          onChange={(e) => handleApprovalDetails('link', e.target.value)}
        />
      }
      {/* {!isEdit && ( */}
        <FormInputSelect
          label="Team member email"
          placeholder="james@swiftapprove.com"
          data={members.map((member) => ({ id: member.id, name: member.name }))}
          handleDataSelect={handleMembersSelect}
          selectedData={selectedMembers.map((member) => ({
            id: member.id,
            name: member.name,
          }))}
        />
      {/* )} */}
      {/* {!isEdit && ( */}
        <button
          className="bg-primary text-white rounded-lg py-2.5 px-5 w-full"
          onClick={nextStep}
          disabled={isSubmitting}
        >
          Next
        </button>
      {/* )} */}
    </>
  );
};

export default AddApprovalRequestDetails;
