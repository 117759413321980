import { FaXmark } from 'react-icons/fa6';
import Attachment from '../SVGs/Attachment';

const CommentFileAttachment = ({ attachment, pos = 1, deleteAction }) => {
  return (
    <div className="flex items-center gap-2 p-1 bg-gray-100 rounded text-[10px]">
      <div className="flex items-center gap-2 px-1 rounded">
        <a
          href={attachment.url}
          target="_blank"
          className="flex gap-1 text-nowrap justify-center items-center"
        >
          <Attachment size="15" />{' '}
          <span className="no-wrap">Attachment {pos} </span>
        </a>
        {deleteAction && <span className="cursor-pointer" onClick={deleteAction}>
          <FaXmark className='text-primary' />
        </span>}
      </div>
    </div>
  );
};

export default CommentFileAttachment;
