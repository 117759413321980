/* global Dropbox */
import React, { useEffect, useState } from 'react';
import FormRadioButton from '../Form/FormRadioButton';
import FormInput from '../Form/FormInput';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { lazyImageLoader } from '../../utils/html-utils';
import { getInputAccept } from '../../utils/upload-utils';
import uploadVideoToS3 from '../../utils/uploadVideoToS3';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../recoil/userAtoms';
import { Logger } from '../../utils/logger';
import useDrivePicker from 'react-google-drive-picker';

const SocialUploadSelector = ({
  onFileChange,
  closeUploadModal,
  setTitle,
  setHtmlContent,
  setURL,
  addImageData,
  setPlatform,
  loading,
  setLoading,
  category,
  platform,
}) => {
  const [openPicker, authResponse] = useDrivePicker();
  const [user] = useRecoilState(userAtom);
  const fileUploadRef = React.createRef(null);
  const toast = useToast();
  const [fileProgress, setFileProgress] = useState({});
  const [isPolling, setIsPolling] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  setPlatform(category, 'post');

  const [selectedDocumentContentType, setSelectedDocumentContentType] =
    useState(platform);
  const [selectedUploadMethod, setSelectedUploadMethod] = useState('device');

  const [stage, setStage] = useState(1);

  useEffect(() => {
    setTitle('Select your upload method');
  }, []);

  const handleUploadMethodChange = (value) => {
    setSelectedUploadMethod(value);
  };
  const [videoUrls, setVideoUrls] = useState([]);

  useEffect(() => {
    if (!selectedData?.docs?.length) return;

    async function download(data) {
      try {
        const imageDataArray = [];
        const videoDataPromises = [];

        // Separate image and video files from Google Drive
        const imageDocs = data.docs.filter((doc) =>
          doc.mimeType.includes('image/')
        );
        const videoDocs = data.docs.filter((doc) =>
          doc.mimeType.includes('video/')
        );
        // setNumVideosUploaded(numVideosUploaded + videoDocs.length);
        // setNumImagesUploaded(numImagesUploaded + imageDocs.length);

        setLoading(true);
        for (const doc of data.docs) {
          const response = await fetch(
            `https://www.googleapis.com/drive/v3/files/${doc.id}?alt=media`,
            {
              headers: new Headers({
                Authorization: `Bearer ${authResponse?.access_token}`,
              }),
            }
          );

          const blob = await response.blob();

          if (doc.mimeType.includes('image/')) {
            // For images, convert to base64 and add to imageDataArray
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            await new Promise((resolve) => {
              reader.onloadend = () => {
                imageDataArray.push({
                  data: reader.result, // Base64 string
                  type: 'image',
                  name: doc.name,
                });
                resolve();
              };
            });
          } else if (doc.mimeType.includes('video/')) {
            // For videos, prepare for uploading to AWS
            videoDataPromises.push(
              new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (e) =>
                  resolve({ data: e.target.result, media: 'video' });
                reader.readAsDataURL(blob);
              })
            );
          }
        }

        imageDataArray.map((data) => {
          addImageData(data);
        });

        // Initialize progress for video uploads
        const initialProgress = videoDocs.reduce((acc, _, index) => {
          acc[`file${index}`] = 0;
          return acc;
        }, {});
        setFileProgress(initialProgress);

        // Handle video upload
        const videosData = await Promise.all(videoDataPromises);
        const uploadResults = await Promise.all(
          videosData.map((data, index) => {
            return uploadVideoToS3([data], user, (progress) => {
              setFileProgress((prevProgress) => ({
                ...prevProgress,
                [`file${index}`]: progress,
              }));
            });
          })
        );

        uploadResults.forEach((result) => {
          setIsPolling(true);
          pollForVideoUrl(result[0].key);
        });

        setLoading(false);
      } catch (error) {
        Logger.log('error', error);
        setLoading(false);
      }
    }

    download(selectedData);
  }, [selectedData]);

  const openDropboxChooser = () => {
    Dropbox.choose({
      success: function (files) {
        // 'files' is an array of file objects. We'll map over this array to extract the links.
        const fileLinks = files.map((file) => file.link);
        // Pass the array of links to the handler
        handleDropboxFiles(fileLinks);
        // setIsModalOpen(false);
        closeUploadModal();
      },
      linkType: 'direct', // "direct" gives a direct link to the file, which we can convert to a blob
      multiselect: true, // Allow multiple files to be selected
      extensions: ['.jpg', '.png', '.mp4'],
    });
  };

  const loadDropboxSDK = (callback) => {
    const script = document.createElement('script');
    script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
    script.id = 'dropboxjs';
    script.dataset.appKey = 'YOUR_APP_KEY'; // Ensure to replace with your actual App key
    script.onload = callback;
    document.body.appendChild(script);
  };

  const handleDropboxFiles = async (fileLinks) => {
    // Map over the fileLinks to create an array of fetch requests
    const fetchPromises = fileLinks.map((fileLink) => fetch(fileLink));

    // Use Promise.all to wait for all of them
    const responses = await Promise.all(fetchPromises);

    // Convert responses to blobs
    const blobs = await Promise.all(
      responses.map((response) => response.blob())
    );

    // Create a new array of image data from the blobs
    const newImageData = await Promise.all(
      blobs.map(async (blob, index) => {
        // Consider extracting the actual filename and MIME type from the Dropbox response
        const file = new File([blob], `filename${index}`, { type: blob.type });

        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve({
              name: file.name,
              data: reader.result,
              type: file.type.startsWith('image') ? 'image' : 'video',
            });
          };

          reader.readAsDataURL(file);
        });
      })
    );

    // Update the state with the new image data, overwriting any existing data
    // setNumImagesUploaded(numImagesUploaded + newImageData.length);

    newImageData.forEach((imageData) => {
      addImageData(imageData);
    });
  };

  const handleOpenPicker = (type = 'DOCS_IMAGES_AND_VIDEOS') => {
    openPicker({
      clientId:
        '701177215582-m10tn0hun38pudm6aqdrp4asei35pm1q.apps.googleusercontent.com',
      developerKey: 'AIzaSyCBGHYJMUeVOAlZEf9AeYjD7ULZIVNS-jo',
      viewId: type,
      token: authResponse?.access_token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: async (data) => {
        if (data.action === 'cancel') {
          Logger.log('User clicked cancel/close button');
        } else if (data.action === 'picked') {
          setSelectedData(data);
        }
      },
      authImmediate: true,
    });
  };

  const handleImageUpload = async (event) => {
    // setIsModalOpen(false);
    setLoading(true);

    const files = Array.from(event.target.files);
    if (!files.length) return;
    // files.forEach((file) => Logger.log(file.type))
    // Separate image and video files
    const imageFiles = files.filter((file) => file.type.startsWith('image'));
    const videoFiles = files.filter((file) => file.type.startsWith('video'));
    // const docFiles = files.filter((file) => file.type.includes(selectedDocumentContentType));

    // setNumVideosUploaded(numVideosUploaded + videoFiles.length);
    // setNumImagesUploaded(numImagesUploaded + imageFiles.length);
    // setNumDocsUploaded(numDocsUploaded + docFiles.length);
    // Add image data directly

    const newImageData = await Promise.all(
      imageFiles.map(async (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve({
              name: file.name,
              data: reader.result,
              type: 'image',
            });
          };

          reader.readAsDataURL(file);
        });
      })
    );

    newImageData.forEach((imageData) => {
      addImageData(imageData);
    });

    await videoUploader(videoFiles);

    if (!isPolling && !videoFiles.length) {
      setLoading(false);
      closeUploadModal();
    }
  };

  const videoUploader = async (videoFiles) => {
    if (!videoFiles.length) return;
    // Initialize progress for video uploads
    const initialProgress = videoFiles.reduce((acc, _, index) => {
      acc[`file${index}`] = 0;
      return acc;
    }, {});
    setFileProgress(initialProgress);
    // setImageDeleted(false);

    // Upload video files
    const videoDataPromises = videoFiles.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) =>
          resolve({ data: e.target.result, media: 'video' });
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    try {
      const videosData = await Promise.all(videoDataPromises);
      const uploadResults = await Promise.all(
        videosData.map((data, index) => {
          return uploadVideoToS3([data], user, (progress) => {
            setFileProgress((prevProgress) => ({
              ...prevProgress,
              [`file${index}`]: progress,
            }));
          });
        })
      );

      uploadResults.forEach((result) => {
        setIsPolling(true);
        pollForVideoUrl(result[0].key);
      });
    } catch (error) {
      Logger.error('Error processing files:', error);
    }
  };

  const pollForVideoUrl = async (uploadPath) => {
    try {
      const response = await axios.post(`/api/auth/check-url`, {
        partialPath: uploadPath,
      });
      if (response.data.found) {
        setVideoUrls((prevUrls) => [...prevUrls, response.data.url]);
        addImageData({
          name: response.data.url,
          data: response.data.url,
          type: 'video',
        });
        Logger.log('xxx');
        setLoading(false);
        closeUploadModal();
        if (videoUrls.length + 1 === Object.keys(fileProgress).length) {
          setIsPolling(false);
          setFileProgress({});
          Logger.log('fileProgress ', fileProgress);
        }
      } else {
        setTimeout(() => pollForVideoUrl(uploadPath), 2500);
      }
    } catch (error) {
      Logger.error('Error polling for video URL:', error);
    }
  };

  const handleNext = () => {
    if (selectedUploadMethod === 'device') {
      fileUploadRef.current.click();
    } else if (selectedUploadMethod === 'dropbox') {
      // Open Dropbox modal
      // openDropboxModal();
      loadDropboxSDK();
      openDropboxChooser();
    } else if (selectedUploadMethod === 'google-drive') {
      // Open Google Drive modal
      // openGoogleDriveModal();
      handleOpenPicker();
      // closeUploadModal(false);
    }
  };

  const handlePrev = () => {
    if (stage === 2) {
      setTitle('Select the type of document');
      setStage(1);
    } else if (stage === 1) {
      closeUploadModal();
    }
  };

  return (
    <div className="flex flex-col items-center gap-2 text-sm pt-2">
      <>
        <div className="flex justify-start w-full">
          <FormRadioButton
            labels={[
              {
                label: 'Device',
                value: 'device',
              },
              {
                label: 'Dropbox',
                value: 'dropbox',
              },
              {
                label: 'Google Drive',
                value: 'google-drive',
              },
            ]}
            onClick={handleUploadMethodChange}
            defaultValue={selectedUploadMethod}
          />
        </div>
      </>

      <input
        ref={fileUploadRef}
        type="file"
        className="hidden"
        onChange={handleImageUpload}
        accept={getInputAccept()}
        // accept="text/html"
      />

      <div className="flex justify-between w-full gap-4 mt-2">
        <button
          className="border rounded-lg py-2.5 px-5 w-full"
          onClick={handlePrev}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          className="bg-primary text-white rounded-lg py-2.5 px-5 w-full"
          onClick={handleNext}
          disabled={loading}
        >
          {loading ? 'Please wait' : stage === 1 ? 'Confirm' : 'Upload'}{' '}
        </button>
      </div>
    </div>
  );
};

export default SocialUploadSelector;
