import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { activeWorkspaceAtom } from '../../recoil/workspaceAtom';

const WorkspaceCard = ({ data, handleDelete, handleEdit, handleViewMembers}) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [, setActiveWorkspace] = useRecoilState(activeWorkspaceAtom);

  return (
    <div
      className={`border max-w-[276px] w-full border-t-8 rounded-lg p-4 flex flex-col items-center justify-between relative`}
      style={{
        borderTopColor: data?.color === '#ffffff' ? '#000000' : data?.color,
      }}
    >
      <div className="absolute right-0 top-0 cursor-pointer">
        <div
          className="origin-center rotate-90 text-xl"
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          ...
        </div>
        {showMenu ? (
          <div
            className="absolute right-0 px-3 py-2 border-gray-700 rounded-md flex flex-col gap-1.5 text-sm text-center bg-gray-100"
            onMouseLeave={() => {
              setShowMenu(false);
            }}
          >
            <div
              className="text-rose-700"
              onClick={() => {
                setShowMenu(false);
                handleDelete();
              }}
            >
              Delete
            </div>
            <div
              className=""
              onClick={() => {
                setShowMenu(false);
                handleEdit();
              }}
            >
              Edit
            </div>
            <div
              className=""
              onClick={() => {
                setShowMenu(false);
                handleViewMembers();
              }}
            >
              Member
            </div>
          </div>
        ) : null}
      </div>
      <div
        className="flex flex-col items-center gap-3.5 cursor-pointer"
        onClick={() => {
          setActiveWorkspace(data.name);
          navigate(`/workspace/${data._id}`);
        }}
      >
        <div
          className={`sm:w-14 sm:h-14 w-9 h-9 border rounded-full flex items-center justify-center text-3xl font-semibold text-black overflow-hidden`}
          style={{
            backgroundColor: data?.color,
          }}
        >
          {data?.icon ? (
            <img
              src={data?.icon}
              alt={data?.name}
              className="w-full h-full object-cover"
            />
          ) : (
            data?.name?.[0]
          )}
        </div>
        <h2 className="font-semibold text-center">{data?.name}</h2>
      </div>
      <div className="flex flex-col items-center gap-1.5">
        <p className="text-xs text-center">
          {data?.pendingApprovers} pending approval{data?.pendingApprovers == 1 ? '' : 's'}
        </p>
        <p className="text-xs text-center">
          {data?.workspaceMembers?.length} member{data?.workspaceMembers?.length == 1 ? '' : 's'}
        </p>
      </div>
    </div>
  );
};

export default WorkspaceCard;
